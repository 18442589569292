.network-dropdown-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    width: 300px;
    background: #1c1924;
    border: 2px solid #c2c4d3;
    border-radius: 20px;
    top: 70px;
    left: -100px;
}

.ndnl-cont-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nd-network-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    margin: 8px 0px;
    cursor: pointer;
}

.nd-network-icon {
    margin-right: 10px;
}

.nd-network-name {
    font-size: 18px;
}

.ndnn-coming-soon {
    color: #ffffffa5;
}

.coming-soon {
    color: #ffffff4a;
    font-size: 14px;
}