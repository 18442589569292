.wallet_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wallet-connect-btn {
    background: linear-gradient(90deg, #18102d, #18102d, #18102d) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border: 2px solid transparent;
    border-radius: 28px;
    border: 2px solid transparent;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.wlt-cnt-btn {
    padding: 10px;
    cursor: pointer;
}

.create-token-container .wlt-cnt-btn {
    display: flex;
}

.nav-container .wlt-cnt-btn-not-cnt {
    padding: 10px 50px !important;
    background: linear-gradient(90deg, #424452, #424653, #424752) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border: 2px solid transparent;
    border-radius: 28px;
    border: 2px solid transparent;
    font-size: 17px;
    font-weight: 500;
}

.wlt-cnt-cpy-btn {
    cursor: pointer;
    padding-right: 10px;
    display: flex;
}

.wlt-cnt-btn-not-cnt {
    padding: 10px 50px !important;
    background: linear-gradient(90deg, #18102d, #18102d, #18102d) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border: 2px solid transparent;
    border-radius: 28px;
    border: 2px solid transparent;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
}

.wlt-cnt-btn-wrng-net {
    padding: 10px 50px !important;
    background: #9e0505;
    border-radius: 28px;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
}