.create-token-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ct-top-cont {
  text-align: center;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ct-top-cont>div {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}

.ct-top-cont>p {
  font-size: 18px;
  font-weight: 400;
  color: #ffffffcc;
  margin-bottom: 20px;
}

.ct-ctt {
  font-size: 30px;
  font-weight: 600;
  margin: 70px 0px 10px 0px;
  text-align: center;
}

.ct-box {
  display: flex;
  /* width: 80%; */
  flex-wrap: wrap;
  justify-content: center;
}

.ct-sec-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 65px 15px;
}

.ct-sec-container div:nth-child(1) {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.ct-sec-container div:nth-child(2) {
  font-size: 19px;
  font-weight: 400;
  color: #ffffffcc;
  width: 75%;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .ct-sec-container div:nth-child(2) {
    width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .ct-top-cont {
    width: 90%;
    margin: 0 au;
  }
}