.termsCondition-conainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.termsCond-top-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 80px;
}

.termsCond-top-sec>div:first-child {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

.termsCond-top-sec>div:nth-child(2) {
    font-size: 17px;
    text-align: center;
    color: #ffffff99;
}

.tc-content-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 40px;
}

.tc-content-sec>div:first-child {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

.tc-content-sec>div:nth-child(2) {
    text-align: center;
    color: #ffffff99;
}

.miscelleanous-text {
    text-align: center;
    color: #ffffff99;
    margin: 5px 0px;
}

@media screen and (max-width: 900px) {
    .termsCond-top-sec {
        width: 80%;
    }

    .tc-content-sec {
        width: 80%;
    }
}