.token-create-cont {
    width: 370px;
    padding: 25px;
    background: linear-gradient(90deg, #2c263f, #2d2e41, #303643) padding-box,
        linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border-radius: 20px;
    border: 2px solid transparent;
    margin: 20px;
    cursor: pointer;
}
@media screen and (max-width: 1230px) {
    .token-create-cont{
        width: 340px;
    }
}
@media screen and (max-width: 720px) {
    .token-create-cont{
        width: 355px;
    }
}
.tc-first-row {
    position: relative;
    margin-bottom: 20px;
}

.tcfr-text {
    font-size: 28px;
    font-weight: 600;
}

.tc-badge-cont {
    position: absolute;
    right: -35px;
    top: -5px;
}

.tc-triangle-design {
    height: 17px;
    width: 17px;
    background: #4258d8;
    position: relative;
    transform: rotate(-45deg);
    top: 21.5px;
    left: -6.5px;
    z-index: 1;
    border-radius: 2px;
}

.tc-badge {
    background: linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 120px;
    border-radius: 5px;
    position: relative;
    z-index: 2;
}

.tc-sec-row {
    font-size: 17px;
    color: #ffffffb3;
    height: 280px;
}