.token-creation-container {
    width: 50%;
}

.token-creation-top-cont {
    text-align: center;
    margin-bottom: 30px;
}

.token-creation-top-cont>div:first-child {
    font-size: 44px;
    font-weight: 600;
    margin-bottom: 20px;
}

.token-creation-top-cont>p {
    font-size: 19px;
    font-weight: 400;
    color: #ffffffcc;
    margin-bottom: 20px;
}

.token-creation-top-cont>section {
    margin-top: 25px;
}

.ultimate-token-container .wlt-cnt-btn-not-cnt {
    background: linear-gradient(90deg, transparent, transparent, transparent) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
}

@media screen and (max-width:480px) {
    .token-creation-container {
        width: 90%;
    }
}