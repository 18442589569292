.token-create-main-cont {
  width: 66%;
  margin-top: 40px;
}

.token-create-heading-cont {
  border-bottom: 1px solid #ffffff4d;
  padding: 20px 12px;
}

.token-create-heading-cont>div:first-child {
  font-size: 26px;
  font-weight: 500;
}

.token-create-heading-cont>div:nth-child(2) {
  font-size: 18px;
  color: #ffffffb3;
}

.token-input-cont-col2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 15px;
  border-bottom: 1px solid #ffffff4d;
}

.ticc2-input {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.ticc2-input>div {
  margin-bottom: 15px;
}

.ticc2-select-cont {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  border-bottom: 1px solid #ffffff4d;
}

.ticc2-select-cont>div:first-child {
  margin-bottom: 15px;
}

.tc-toggle-cont {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
}

.tc-toggle-cont>div:first-child {
  width: 80%;
}

.tc-toggle-cont>div>div:first-child {
  font-size: 18px;
}

.tc-toggle-cont>div>div:nth-child(2) {
  font-size: 16px;
  color: #ffffffb2;
}

.tkp-fund-text {
  font-size: 16px !important;
  color: #ffffffb2;
}

.tc-decimal-count {
  font-size: 18px;
}

.tc-range-cont {
  padding: 0px 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nxt-prev-btn-cont {
  float: right;
  margin-top: 30px;
  margin-right: 15px;
}

.nxt-prev-btn-cont>button:first-child {
  margin-right: 20px;
}

/************************************ Additional Features ***********************************/
.tc-additional-feature-heading-cont {
  padding: 20px 12px;
}

.tc-additional-feature-heading-cont>div:first-child {
  font-size: 26px;
  font-weight: 500;
}

.tc-additional-feature-heading-cont>div:nth-child(2) {
  font-size: 18px;
  color: #ffffffb3;
}

.tc-toggle-cont-additional-feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  background: linear-gradient(90deg, #1a192f, #1a1a30, #1b1b30) padding-box,
    linear-gradient(90deg, #3e4ed3 -5%, #66aafe 50.42%, #7027c8 105.83%) border-box;
  margin: 20px 0px;
  border-radius: 18px;
  border: 2px solid transparent;
}

.tc-toggle-cont-additional-feature>div>div:first-child {
  font-size: 18px;
}

.tc-toggle-cont-additional-feature>div>div:nth-child(2) {
  font-size: 16px;
  color: #ffffffb2;
}

/************************************ Token Supply ***********************************/
.token-supply-input-cont-col2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 15px;
}

.tsicc2-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.tsicc2-input>div {
  margin-bottom: 15px;
}

/******************************** Liquidity Options ******************************************/
.tc-liq-section-cont {
  border-bottom: 1px solid #ffffff4d;
  border-top: 1px solid #ffffff4d;
}

.tc-liq-token-supply {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 15px;
}

.tc-liq-token-supply>div:first-child {
  margin-right: 20px;
  width: 48%;
}

.tc-liq-token-supply>div:nth-child(2) {
  width: 48%;
}

.tc-liq-total-supply-bar-cont {
  display: flex;
  margin-top: 25px;
}

.tc-liq-total-supply-bar-cont .range {
  width: 85%;
  margin-right: 20px;
}

.tc-liq-tok-pr-cont {
  position: relative;
}

.tc-liq-tok-price {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
}

.tc-liq-tok-price>div:first-child {
  margin-right: 20px;
  width: 48%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 15px 30px;
}

.tc-liq-tok-price>div:first-child>div:first-child {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 18px;
}

.tc-liq-tok-price>div:first-child>div:nth-child(2) {
  font-size: 18px;
}

.tc-liq-tok-price>div:nth-child(2) {
  width: 48%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 15px 30px;
}

.tc-liq-tok-price>div:nth-child(2)>div:first-child {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 18px;
}

.tc-liq-tok-price>div:nth-child(2)>div:nth-child(2) {
  font-size: 18px;
}

.tc-liq-circle-design {
  background: white;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 30px;
  left: 63px;
}

.tc-liq-circle-design>div:first-child {
  color: #000000cc;
  font-size: 50px;
  margin-bottom: 5px;
}

.tc-liq-tok-text {
  padding: 20px 15px;
}

.tc-liq-tk-pr-sc-cont>div:first-child {
  align-items: flex-start;
}

.tc-liq-cont .ticc2-select-cont>div:first-child {
  margin-bottom: 10px;
}

.tc-liq-cont .ticc2-select-cont>div:nth-child(2) {
  margin-bottom: 20px;
  color: #ffffffb2;
}

.tc-liq-action-cont {
  padding: 20px 15px;
  border-bottom: none;
}

.tc-liq-action-cont>div:nth-child(3) {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #ffffff80;
  border-radius: 5px;
  margin-bottom: 15px;
}

.liquidity-toggle-feature {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #ffffff80;
  border-radius: 5px;
  margin-bottom: 15px;
}

.tc-liq-non-brn-lck {
  width: 33%;
  text-align: center;
  padding: 10px 0px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}

.tc-liq-non-brn-lck-active {
  width: 33%;
  text-align: center;
  padding: 10px 0px;
  background: #ffffff80;
  border-radius: 5px;
  color: black;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}

.tc-liq-action-cont>div:nth-child(4) {
  color: #ffffffb2;
}

/**********************************   ************************************/
.tc-deploy-token {
  padding: 20px 15px;
  border-bottom: 1px solid #ffffff4d;
}

.tc-deploy-token-ut {
  padding: 15px;
  border-bottom: 1px solid #ffffff4d;
}

.tcdt-fee-details-cont {
  margin-top: 15px;
}

.tcdt-fee-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.tcdt-fee-text>div:nth-child(2) {
  color: #ffffffcc;
}

.tcdt-total-fee-text {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
}

.tc-deploy-token-wallet-cnt-btn {
  text-align: center;
  padding: 20px 15px;
}

.tc-deploy-token-wallet-cnt-btn .wlt-cnt-btn-not-cnt {
  padding: 12px 160px;
}

/**************************** Configure Dividends ********************************/
.tc-config-dividend {
  padding: 20px 15px;
  border-bottom: 1px solid #ffffff4d;
}

.tc-config-dividend>div:first-child {
  margin-bottom: 50px;
}

.tc-config-dividend>div:first-child>div:first-child {
  margin-bottom: 5px;
  font-size: 17px;
}

.tc-config-dividend>div:first-child>div:nth-child(2) {
  margin-bottom: 15px;
  font-size: 17px;
  color: #ffffffb2;
}

.tc-config-dividend>div:nth-child(2)>div:first-child {
  margin-bottom: 5px;
  font-size: 17px;
}

.tc-config-dividend>div:nth-child(2)>div:nth-child(2) {
  margin-bottom: 15px;
  font-size: 17px;
  color: #ffffffb2;
}

.tc-config-dividend>div:nth-child(2)>input {
  width: 100%;
}

.tc-config-dividend .tc-range-cont {
  padding: 0px;
}

/**************************** Dividend Configure Fees ********************************/
.tc-dividend-config-fee {
  padding: 7px 15px 50px 15px;
}

.tcdcf-bar-cont {
  display: flex;
  margin-top: 10px;
}

.tcdcf-bar-cont .range {
  margin-right: 20px;
}

/**************************** Input type range tage design ********************************/
.range_slider {
  width: 96%;
}

.range_slider [type="range"] {
  width: 100%;
  -webkit-appearance: none;
  height: 11px;
  border-radius: 6px;
  background: #FFFFFF4D;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.range_slider [type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: linear-gradient(90deg, #66AAFE, #66AAFE, #66AAFE) padding-box,
    linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
  border: 3px solid transparent;
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}

.range_slider [type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #f0932b;
  border: #f9ca24 5px solid;
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

/******************************** Deflationary Token ****************************/

.dt-tsicc2-input>div {
  margin-bottom: 0px !important;
}

.dt-tc-liq-section-cont {
  border-bottom: 1px solid #ffffff4d;
}

.dt-ticc2-select-cont {
  display: flex;
  flex-direction: column;
  padding: 0px 15px 20px 15px;
}

.dt-ticc2-select-cont>div:first-child {
  margin-bottom: 15px;
}

.liquidity-option-border {
  border-bottom: none;
}

.summary-heading {
  font-size: 24px;
  font-weight: 500;
  color: #ffffffc4;
}

/*********************************** Tooltip CSS ************************************/
.input-heading-cont {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tooltip-design {
  border-radius: 50%;
  height: 13px;
  width: 13px;
  font-size: 9px;
  border: 1px solid #ffffffcc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffffcc;
  cursor: default;
  margin-left: 8px;
  margin-top: 5px;
}

.tooltip-design-liq-section {
  margin-left: 130px;
}

@media screen and (max-width: 1100px) {
  .config-divident-toggle>label {
    width: 80px;
  }
}

@media screen and (max-width: 900px) {
  .toggle-mk-rs-sm>label {
    width: 80px;
  }
}

@media screen and (max-width: 720px) {
  /* .liquidity-option-toggle .switch {
    width: 100px;
  } */

  .config-divident-toggle>label {
    width: 120px;
  }
}

@media screen and (max-width: 480px) {
  .ticc2-input {
    width: 100%;
    margin: 12px 0px;
  }

  .token-create-main-cont {
    width: 90%;
  }

  .tc-toggle-cont {
    margin: 0 auto;
    width: 95%;
    background: linear-gradient(90deg, #1a192f, #1a1a30, #1b1b30) padding-box,
      linear-gradient(90deg, #3e4ed3 -5%, #66aafe 50.42%, #7027c8 105.83%) border-box;
    border: 2px solid transparent;
    border-radius: 28px;
    border: 2px solid transparent;
    align-items: center;
    margin-bottom: 20px;
  }

  .tc-liq-section-cont {
    border-top: none;
    border-bottom: none;
  }

  .ticc2-select-cont {
    border: none;
  }

  .nxt-prev-btn-cont>button:first-child {
    width: 137px;
  }

  .nxt-prev-btn-cont>button:last-child {
    width: 102px;
  }

  .additional-feature-card-main {
    width: 90%;
  }

  /* .toggle-parent-0 .switch {
    width: 65px;
  } */

  .toggle-parent-3 .switch {
    width: 75px;
  }

  .token-offer-btn {
    border: 1px solid #ffffff;
    border-radius: 28px;
    width: 50%;
    margin: 0 auto;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: center;
    margin-top: 20px;
  }

  .deploy-token-border-none {
    border-bottom: 0;
    padding: 0;
  }

  .deploy-token-border-none>div:last-child {
    margin-bottom: 0;
  }

  .input-heading-cont {
    margin-top: 10px;
  }

  .connect-wallet-bg-res-bg>section>button {
    background: linear-gradient(90deg,
        #3e4ed3 -5%,
        #66aafe 50.42%,
        #7027c8 105.83%);
    width: 300px;
    height: 65px;
    border-radius: 34px;
  }

  .tc-config-dividend {
    border-bottom: none;
  }

  .config-divident-toggle>label {
    width: 120px;
  }

  .tc-box-responsive {
    background: none;
    margin: 0;
  }

  .rth-top-res {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .rth-top-res>div:last-child {
    margin-left: 0 !important;
  }

  .tc-liq-tok-price>div:first-child {
    margin-right: 0px;
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    align-items: flex-start;
  }

  .tc-liq-tok-price>div:nth-child(2) {
    width: 100%;
    height: 100px;
  }

  .tc-liq-circle-design {
    bottom: -19px;
    left: 114px;
  }

  .tc-liq-circle-design>div:first-child {
    color: #000000cc;
    font-size: 50px;
    margin-bottom: 5px;
  }

  .tooltip-design-liq-section {
    margin-left: 0px;
    position: relative;
    left: 130px;
  }

  .review-token-box {
    width: 90%;
  }

  /* Abhishek */
}

/************************** Liquidity coming soon badge style ***************************/
.liquidy-coming-soon {
  position: absolute;
  top: -8px;
  left: 210px;
}

.tc-triangle-design-liq {
  height: 12.5px;
  width: 13px;
  background: #4258d8;
  position: relative;
  transform: rotate(-45deg);
  top: 14.9px;
  left: -3.5px;
  z-index: 1;
  border-radius: 2px;
}

.tc-badge-liq {
  background: linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  width: 120px;
  border-radius: 5px;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 480px) {
  .tc-liq-token-suppl-res>div:first-child {
    width: 92%;
    margin: 0 auto;
  }

  .tc-liq-token-suppl-res>div:last-child {
    width: 100%;
  }

  .tc-liq-tl-bar-res {
    flex-direction: column-reverse;
    align-items: flex-end;
    margin: 0;
  }

  .tc-liq-tl-bar-res .range {
    width: 100%;
    margin: 0;
  }

  .br-cnt-2x {
    width: 94%;
    margin: 0 auto;
  }

  .xnjk-ksdn {
    width: 95%;
    margin: 0 auto;
  }

  .toggle-mk-rs-sm>label {
    width: 100px;
  }

  .tc-liq-token-supply>div:first-child {
    width: 100%;
    margin: 0;
  }

  .tc-liq-token-supply>div:last-child {
    width: 100%;
    margin-top: 20px;
  }

  /* 
  .tc-toggle-cont-additional-feature .switch {
    width: 80px;
  } */

}