.popup_container {
    position: relative;
    padding: 20px;
    border-radius: 15px 15px 20px 20px;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
    color: #ffffff;
    background: linear-gradient(90deg, #2c263f, #2d2e41, #303643) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border-radius: 30px;
    font-size: 22px;
    display: flex;
    width: 570px;
}

.success_icon_img {
    height: 40px !important;
    width: 40px !important;
}


.dep_suc_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 50px;
    width: 80%;
}


.vw_ln_tkn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #2c263f, #2d2e41, #303643) padding-box,
        linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border: 2px solid transparent;
    border-radius: 30px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
}

.suc_modal_txt2 {
    font-size: 14px;
    display: inline-block;
    margin: 10px 0px 22px 0px; 
}

.vw_ln_tkn_img {
    height: 13px !important;
    width: 13px !important;
}

.vw_ln_tkn_txt {
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    margin: 0px;
}


/******************* Contact Modal CSS  ******************/
.success_icon_img_contact {
    height: 40px !important;
    width: 40px !important;
    margin-top: 2px;
}

.vw_ln_tkn_contact {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #2c263f, #2d2e41, #303643) padding-box,
        linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border: 2px solid transparent;
    border-radius: 30px;
    padding: 12px 15px;
    cursor: pointer;
    width: 100%;
}

/* .vw_ln_tkn_contact2 {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%);
    border: 2px solid transparent;
    border-radius: 30px;
    padding: 12px 15px;
    cursor: pointer;
    width: 100%;
} */

.dep_suc_cont_contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
}

.contact-modal-text {
    margin-bottom: 20px;
}

.contact-modal-text>p:first-child {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 12px;
}

.contact-modal-text>p:nth-child(2) {
    font-size: 18px;
    color: #ffffffb7;
    text-align: center;
    font-weight: 300;
}

.contact-modal-text>p:nth-child(3) {
    font-size: 18px;
    color: #ffffffb7;
    text-align: center;
    font-weight: 300;
}

@media screen and (max-width: 700px) {
    .popup_container {
        padding: 20px;
        width: 90%;
    }

    .success_icon_img {
        height: 30px !important;
        width: 30px !important;
    }

    .dep_suc_cont {
        margin-left: 30px;
    }
}

@media screen and (max-width: 500px) {
    .popup_container {
        padding: 15px;
        width: 90%;
    }

    .success_icon_img {
        height: 30px !important;
        width: 30px !important;
    }

    .dep_suc_cont {
        margin-left: 20px;
    }

    .suc_modal_txt1 {
        font-size: 18px;
        font-weight: 500;
    }

    .suc_modal_txt2 {
        font-size: 11px;
    }
}