.token-features-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 40px;
    width: 80%;
}

.token-features-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32%;
    margin-bottom: 40px;
    background: linear-gradient(90deg, #18102D, #18102D, #18102D) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border-radius: 20px;
    border: 2px solid transparent;
    padding: 20px;
}

.token-features-container div:nth-child(1) {
    border-radius: 50%;
    background-color: #FFFFFF1A;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.token-features-container div:nth-child(2) {
    font-size: 18px;
    font-weight: 500;
    color: #ffffffcc;
    margin-bottom: 10px;
}

.token-features-container div:nth-child(3) {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
}

.token-features-container div:nth-child(4) {
    font-size: 18;
    font-weight: 500;
    color: #ffffffc2;
    text-align: center;
}
.kfc-mcd-main{
    width: 90%;
    margin: 0 auto;
}
@media screen and (max-width: 720px){
    .token-features-main{
        flex-direction: column;
        align-items: center;
    }
    .token-features-container {
        width: 90%;
        margin-bottom:'27px';
    }
}