@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(111.88deg, #18102D 7.93%, #203136 95.43%); */
  background: #18102D;
  font-family: "Barlow", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: "Barlow", sans-serif;
}

ul {
  list-style: none;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: #FFFFFF !important;
}

button {
  cursor: pointer;
}