.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ffffff4d;
    padding: 10px 5px 20px 0px;
    margin-top: 40px;
    align-items: center;
    width: 90%;
  }
  
  .footer-typo {
    font-family: Barlow;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: center;
  }
  
  .footer>div:nth-child(2)>a:nth-child(1) {
    margin-right: 20px;
  }
  
  .footer-brnading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 170px;
  }
  
  .footer-brnading>div {
    margin-bottom: 10px;
  }
  
  .footer-brnading>div>p:first-child {
    font-size: 28px;
    font-weight: 500;
    color: #FFFFFF80;
    margin: 0;
  }
  
  .footer-brnading>div>p:nth-child(2) {
    color: #FFFFFF80;
    font-weight: 500;
    margin: 0;
  }
  
  .footer-x-link {
    margin-right: 10px;
  }
  
  @media screen and (max-width: 480px) {
    .footer {
      flex-direction: column-reverse;
      align-items: center;
      gap: 2rem;
    }

    .footer-brnading {
      margin-right: 0px;
    }
  }
  