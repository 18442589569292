.contact-form-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 5px;
}

.contact_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

}

.contact_title {
    color: #FFF;
    text-align: center;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 67px;
    letter-spacing: 6px;
    text-transform: uppercase;
}

.contact_para {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

.contact-input {
    width: 100% !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    padding: 16px !important;
    border: none !important;
    outline: none !important;
    border-radius: 8px !important;
    background: rgba(255, 255, 255, 0.20) !important;
}

.contact-input:focus {
    background: linear-gradient(90deg, #464057, #464057, #464057) padding-box,
        linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box !important;
    border-radius: 8px !important;
    border: 2px solid transparent !important;
}

.form-group input::placeholder {
    color: rgba(255, 255, 255, 0.50);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.option-select {
    color: rgba(255, 255, 255, 0.50);
}

.select-option input::placeholder {
    color: rgba(255, 255, 255, 0.50);
}

.contact-form-button {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    width: 100%;
    padding: 15px 20px;
    border-radius: 29px;
    background: linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%);
    border: none;
    border-radius: 29px;
    cursor: pointer;
}

.contact-form-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    font-size: 1rem;

}

.select-option {
    width: 100% !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    padding: 16px !important;
    border: none !important;
    outline: none !important;
    border-radius: 8px !important;
    background: rgba(255, 255, 255, 0.20) !important;
    background-image: url('../../assets//drop-arrow.svg') !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 25px) center !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    cursor: pointer !important;
}

.select-option:focus {
    background: linear-gradient(90deg, #464057, #464057, #464057) padding-box,
        linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box !important;
    border-radius: 8px !important;
    border: 2px solid transparent !important;
}