.review-token-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
}

.review-token-head-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
}

.review-token-icon-cont {
    border-radius: 50%;
    border: 4px solid white;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-token-head-cont>div:nth-child(2) {
    margin-top: 20px;
    font-size: 35px;
    font-weight: 500;
}

.review-token-box {
    background: linear-gradient(90deg, #18102d, #18102d, #18102d) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border-radius: 20px;
    border: 2px solid transparent;
    padding: 40px 40px 20px 40px;
    width: 40%;
    margin: 30px 0px;
}


.rtt-top-head-sec {
    display: inline-block;
    margin-bottom: 10px;
}

.rtt-arrow-left {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    cursor: pointer;
    height: 15px;
    width: 15px;
}

.rtt-top-head-sec>div:nth-child(2) {
    font-size: 25px;
    font-weight: 500;
    display: inline-block;
    margin-left: 150px;
}

.rt-box-head {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
}

.rt-box-data {
    display: flex;
    margin: 40px 0px;
}

.rt-box-data>div:first-child {
    color: #ffffffcc;
    font-size: 18px;
    width: 50%;
}

.rt-box-data>div:nth-child(2) {
    font-size: 18px;
    width: 50%;
}

.rtt-top-sec {
    padding-bottom: 20px;
}

.rtt-section {
    border-top: 1px solid #ffffff;
    padding: 50px 0px 20px 0px;
}

.rt-btn-cont {
    display: flex;
    width: 40%;
    margin-top: 25px;
}

.rt-btn-cont>button:first-child {
    width: 20%;
    margin-right: 20px;
    padding: 20px 10px;
    border-radius: 40px;
}

.rt-btn-cont>button:nth-child(2) {
    width: 78%;
    padding: 20px 10px;
    border-radius: 50px;
    font-size: 22px;
    font-weight: 500;
}

@media screen and (max-width:720px) {
    .review-token-box {
        width: 90%;
    }

    .phone-size-button {
        width: 100% !important;
    }

    .rt-btn-cont {
        width: 90%;
    }

    .rt-btn-cont>button:nth-child(2) {
        font-size: 17px;
    }
}

@media screen and (max-width:480px) {
    .box-bg {
        background: none;
    }

    .review-token-box {
        width: 90%;
    }

    .rt-btn-cont {
        width: 90%;
    }

    .rt-btn-cont>button:nth-child(2) {
        font-size: 17px;
    }

    .review-token-box {
        padding: 40px 20px 20px 20px;
    }

    .rt-box-data>div:first-child {
        font-size: 16px;
        width: 60%;
    }

    .rt-box-data>div:nth-child(2) {
        font-size: 16px;
        width: 40%;
    }

    .review-token-box .input-heading-cont {
        margin-top: 0px;
    }
}

.popup_bg {
    position: fixed;
    width: 100%;
    z-index: 11111;
    background: #000000cc;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rt-btn-section .wallet_container {
    width: 78%;
}

.rt-btn-section .wlt-cnt-btn-not-cnt {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    border-radius: 50px;
}

.rt-balance-cont {
    display: flex;
    width: 40%;
}

.rt-balance-cont>div:first-child {
    width: 20%;
    margin-right: 20px;
}

.rt-balance-cont>div:nth-child(2) {
    width: 78%;
    margin-top: 10px;
    text-align: center;
}