.box-bg {
  background: linear-gradient(90deg, #2c263f, #2d2e41, #303643) padding-box,
    linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
  border-radius: 20px;
  border: 2px solid transparent;
}

input[type="text"] {
  color: #ffffff;
  border: 1px solid #ffffff;
  outline: none;
  border-radius: 5px;
  background-color: transparent;
  padding: 10px;
  font-size: 17px;
}

input[type="number"] {
  color: #ffffff;
  border: 1px solid #ffffff;
  outline: none;
  border-radius: 5px;
  background-color: transparent;
  padding: 10px;
  font-size: 17px;
  counter-increment: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input::placeholder {
  color: #ffffffb3;
}

select {
  color: #ffffff;
  border: 1px solid #ffffff;
  outline: none;
  border-radius: 5px;
  background-color: transparent;
  padding: 10px;
  font-size: 17px;
  -webkit-appearance: none;
  background-image: url('./assets/down_arrow.png');
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
}

option {
  background: #2d2e41;
  border-radius: 5px;
  border: none;
  font-size: 17px;
  padding: 10px;
}

.gradient-btn {
  background: linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%);
  border-radius: 28px;
  border: none;
  padding: 10px 20px;
}

.grey-btn {
  background: #434d55;
  border-radius: 28px;
  border: none;
  padding: 10px 20px;
}

@media screen and (max-width: 600px) {
  option {
    font-size: 15px;
  }
}