.nav-wrapper {
    display: flex;
    justify-content: center;
}

.mobile-nav-wrapper {
    display: none;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

@media screen and (max-width: 810px) {
    .nav-wrapper {
        display: none;
    }

    .mobile-nav-wrapper {
        display: flex;
    }
}

.nav-container {
    height: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97%;
    margin-top: 20px;
    padding: 0px 20px;
    background: linear-gradient(111.88deg, #3f3850, #403f51, #434853);
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 50px;
    top: 0;
    z-index: 9;
}

.nav-logo-header {
    display: flex;
    align-items: center;
}

.nav-header {
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
}

.nav-list {
    display: flex;
    margin-left: 150px;
}

.nav-list li {
    margin: 0px 20px;
    font-size: 15px;
}

/* .nav-list li:hover {
    
    background: #5E5874;
    border-radius: 20px;
} */

.nav-list img {
    margin-left: 4px;
}

.nav-btn {
    padding: 5px 10px;
    background: linear-gradient(#434a54, #434a54) padding-box, linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    border-radius: 30px;
    border: 2px solid transparent;
}

.content-setter {
    margin-bottom: 150px;
}

.nav-expand-arrow {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 8px;
    margin-bottom: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: 0.5s;
}

.nav-shrink-arrow {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 8px;
    margin-bottom: -1px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transition: 0.5s;
}

.nav-expand-list {
    position: absolute;
    top: 67px;
    background: #403d51;
    border-radius: 10px;
    padding: 10px;
}

.nav-expand-list ul {
    padding-left: 0px;
}

.nav-expand-list li {
    margin: 10px 5px;
    padding: 2px 10px;
}

.nav-expand-list li:hover {
    background: #5E5874;
    border-radius: 20px;
}

.nav-right-btn-cont {
    display: flex;
}

.nav-right-btn-cont > div:first-child {
    margin-right: 30px;
    position: relative;
}

/****************************** Netowrk toggle css ******************************/
.network-toggle {
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;
}

.network-toggle:hover {
    background: rgba(140, 140, 140, 0.553);    
}

.network-toggle-active {
    background: #6f6390;    
}

.network-toggle img {
    margin-right: 5px;
}

.nav-expand-arrow-network-list {
    border: solid #ffffffb8;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    margin-left: 8px;
    margin-bottom: 6px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: 0.5s;
}


.nav-shrink-arrow-network-list {
    border: solid #ffffffb8;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    margin-left: 8px;
    margin-bottom: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transition: 0.5s;
}