.ultimate-token-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
}

.token-step-area {
    display: flex;
    justify-content: space-between;
    width: 71%;
    margin: auto;
    color: #fff;
    position: relative;
    z-index: 1;
    margin-top: 80px;
}

.token-step-single {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    cursor: pointer;
}

.token-step-single .number {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 4px solid #515151;
    background: #383535;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}

.token-step-single .number-visited {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 4px solid transparent;
    background: #383535;
    background: linear-gradient(90deg, #383535, #383535) padding-box,
        linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}

.token-step-single .number-active {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 4px solid transparent;
    background: linear-gradient(90deg, #000000, #000000) padding-box,
        linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    transition: 5s;
}

.token-step-single .info {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}

.token-step-single .info_active {
    color: #65A8FD;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    transition: 5s;
}

.input_error {
    color: #ff0000;
    margin: 5px 0px 0px 10px;
}

/****************************************** Animation *************************************/
.progress {
    height: .6rem;
    background-color: #515151;
    position: absolute;
    top: 21.5px;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    width: calc(100% - 70px);
    z-index: -1;
}


.progress-bar {
    background: linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%);
    border-radius: 20px;
    height: 100%;
    transition: 2s;
}

.progress .progress-bar {
    animation-name: animateBar;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    /* animation-duration: .8s; */
    transition: 2s;
}

@keyframes animateBar {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@media screen and (max-width:480px) {
    .token-step-area {
        width: 90%;
    }
}