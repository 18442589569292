.blue-dex-links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    width: 100%;
}

.blue-dex-links-container>div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blue-dex-links-container>div:nth-child(1)>div {
    font-size: 22px;
    color: #ffffff80;
    font-weight: 600;
}

.bd-links-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 28%;
    margin-top: 30px;
    margin-left: 70px;
}

.bd-links-section>div {
    flex-basis: 165px;
    margin-bottom: 30px;
}

.bd-links-section>div>a {
    padding: 5px 13px;
}

.bd-links-section>div>a:hover {
    background-color: #5E5874;
    border-radius: 20px;
}


@media screen and (max-width: 1180px) {
    .bd-links-section {
        width: 35%;
    }
}

@media screen and (max-width: 950px) {
    .bd-links-section {
        width: 45%;
    }
}

@media screen and (max-width: 600px) {
    .bd-links-section {
        width: 50%;
        justify-content: center;
    }
}