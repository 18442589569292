.faq-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.faq-top-section>div:first-child {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
}

.faq-top-section>div:nth-child(2) {
    font-size: 23.5px;
    margin-bottom: 40px;
    text-align: center;
}

.faq-top-section>div:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.faq-top-section>div:nth-child(3)>a {
    font-size: 19px;
    background: linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%);
    border-radius: 28px;
    padding: 10px 20px;
    width: 180px;
    text-align: center;
    margin: 10px;
}

/************************** FAQ content CSS  ******************************/
.faq-main-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
}

.faq-content-cont {
    width: 50%;
    background: #2e2742;
    padding: 40px 30px;
    border-radius: 15px;
    cursor: pointer;
    margin: 15px 0px;
}

.faq-content-cont-expand {
    background: linear-gradient(90deg, #372e64, #372e64, #372e64) padding-box,
        linear-gradient(90deg, #3E4ED3 -5%, #66AAFE 50.42%, #7027C8 105.83%) border-box;
    box-shadow: 3px 5px 10px #372e649d;
    border: 2px solid transparent;
    width: 50%;
    padding: 40px 30px;
    border-radius: 15px;
    cursor: pointer;
    margin: 15px 0px;
}

.faq-arrow-cont {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 8px 0px 0px 12px;
    background: #ffffff;
    transition: 0.5s;
}

.faq-arrow-cont-expand {
    background: linear-gradient(#2e87b1 10%, #42649c 25%, #534589 60%, #622a79 90%);
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 6px 0px 0px 14.5px;
    transition: 0.5s;
}

.faq-arrow {
    border: solid #000000;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    height: 11px;
    width: 11px;
    transition: 0.5s;
}

.faq-arrow-expand {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    height: 12px;
    width: 12px;
    transition: 0.5s;
}

.faq-head-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.faq-head-cont > div:first-child {
    font-size: 20px;
    width: 90%;
}

.faq-content {
    width: 90%;
    margin-top: 20px;
    font-weight: 200;
}

@media screen and (max-width: 900px) {
    .faq-content-cont {
        width: 70%;
    }
    
    .faq-content-cont-expand {
        width: 70%;
    }    
}

@media screen and (max-width: 500px) {
    .faq-content-cont {
        width: 85%;
    }
    
    .faq-content-cont-expand {
        width: 85%;
    }    
}